import {
    CogIcon,
    LogoutIcon,
    MoonIcon,
    SparklesIcon,
    SunIcon,
    UserCircleIcon,
} from '@heroicons/react/outline';

import {
    BadgeCheckIcon,
    CheckCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/solid';

import { Popover } from '@headlessui/react';
import { Tooltip } from '@mui/material';

import Avatar from '../../components/common/Avatar';
import BetterLink from '../../components/link/better-link';

import { useTheme } from '../../hooks/useTheme';
import { useUser } from '../../hooks/useUser';

import { handleLocaleChange } from '../../utils/i18n';

import { supabase } from '../../services/supabase/client';
import Vera from '../common/Vera';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';
import { useState } from 'react';

export default function DropdownProfile({ desktopOnly }) {
    const router = useRouter();

    const { t, ready, i18n } = useTranslation();
    const { user, userData } = useUser();
    const { darkMode, updateTheme } = useTheme();

    const [showLanguages, setShowLanguages] = useState(false);

    const userDisplayName = userData?.display_name ?? 'No display name';
    const userEmail = user?.email;
    const username = userData?.username;
    const avatarUrl = userData?.['avatar_url'];

    const canvasSize = 80;

    const handleLogout = async () => {
        await supabase.auth.signOut();
    };

    const toggleDarkMode = () => {
        localStorage.setItem('vhp-dark-mode', (!darkMode).toString());
        updateTheme(!darkMode);
    };

    if (!user) return <div></div>;

    return (
        <Popover className={`relative ${desktopOnly && 'hidden md:block'}`}>
            <Tooltip
                title={userDisplayName ?? userEmail ?? 'Open navigation panel'}
                arrow={true}
                placement="bottom-end"
            >
                <Popover.Button
                    className={`flex min-w-[48px] items-center p-2 hover:bg-blue-100/50 dark:hover:bg-zinc-700/40 justify-center rounded-lg transition duration-300 text-black dark:text-white`}
                >
                    <Avatar size={32} />
                </Popover.Button>
            </Tooltip>

            <Popover.Panel className="absolute ring-0 text-black dark:text-white right-0 top-12 z-10">
                {showLanguages ? (
                    <div
                        className={`my-1 flex w-${canvasSize} flex-col rounded-lg bg-white/70 shadow backdrop-blur dark:bg-zinc-800/70`}
                    >
                        <button
                            onClick={() => setShowLanguages(false)}
                            className="flex items-center space-x-2 rounded-t-lg px-4 py-2 text-left font-sans font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <ChevronLeftIcon className="h-5 w-5" />
                            <div>{t('common:back')}</div>
                        </button>
                        <button
                            onClick={() =>
                                handleLocaleChange(
                                    router,
                                    i18n.changeLanguage,
                                    'en',
                                )
                            }
                            className="flex items-center justify-between px-4 py-2 text-lg text-left font-semibold font-sans hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <div className="flex items-center space-x-2">
                                <Image
                                    alt="united-states flag"
                                    src="/flags/united-states.svg"
                                    width={30}
                                    height={30}
                                />
                                <div>English</div>
                            </div>
                            {i18n.language == 'en' && (
                                <CheckCircleIcon className="h-6 w-6 text-green-500 dark:text-green-400" />
                            )}
                        </button>
                        <button
                            onClick={() =>
                                handleLocaleChange(
                                    router,
                                    i18n.changeLanguage,
                                    'vi',
                                )
                            }
                            className="flex items-center justify-between rounded-b-lg font-sans px-4 py-2 text-lg text-left font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <div className="flex items-center space-x-2">
                                <Image
                                    alt="vietnam flag"
                                    src="/flags/vietnam.svg"
                                    width={30}
                                    height={30}
                                />
                                <div>Tiếng Việt</div>
                            </div>
                            {i18n.language == 'vi' && (
                                <CheckCircleIcon className="h-6 w-6 text-green-500 dark:text-green-400" />
                            )}
                        </button>
                    </div>
                ) : (
                    <div
                        className={`my-1 flex w-${canvasSize} flex-col rounded-lg bg-white/70 shadow backdrop-blur-lg dark:bg-zinc-800/70`}
                    >
                        <div className="w-full mx-4 mt-2 flex items-center space-x-2">
                            <div
                                className={`${!avatarUrl && 'mb-2'}
                            flex-shrink-0`}
                            >
                                <Avatar size={65} />
                            </div>
                            <div className="flex flex-col max-w-[11rem]">
                                <div className="line-clamp-1 font-semibold">
                                    {userDisplayName}
                                </div>
                                <div className="text-sm line-clamp-1 text-zinc-700 dark:text-zinc-300">
                                    {username ? `@${username}` : userEmail}
                                </div>

                                <div className="cursor-default mt-1 mb-2 text-xs font-bold flex items-center space-x-2">
                                    <Tooltip
                                        title={
                                            user?.email_confirmed_at
                                                ? 'Your email has been verified'
                                                : 'Your email has not been verified'
                                        }
                                        arrow={true}
                                    >
                                        <div
                                            className={`flex px-2 py-0.5 rounded-full space-x-1 items-center ${
                                                user?.email_confirmed_at
                                                    ? 'text-green-500 dark:text-green-400 bg-green-500/20'
                                                    : 'text-red-500 dark:text-red-400 bg-red-500/20'
                                            }`}
                                        >
                                            <BadgeCheckIcon className="w-5 h-5" />
                                            <div className="text-black dark:text-white">
                                                {user?.email_confirmed_at
                                                    ? 'Verified'
                                                    : 'Not verified'}
                                            </div>
                                        </div>
                                    </Tooltip>

                                    <Tooltip title="0 Vera" arrow={true}>
                                        <div className="flex px-4 py-[0.28rem] rounded-full bg-yellow-500/20 space-x-1 items-center">
                                            <Vera />
                                            <div>0</div>
                                        </div>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        <BetterLink
                            href="/profile"
                            className="px-4 py-2 flex items-center space-x-2 font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <UserCircleIcon className="h-5 w-5" />
                            <div>{ready ? t('common:profile') : 'Profile'}</div>
                        </BetterLink>
                        {/* <BetterLink
                            href="/coming-soon"
                            className="px-4 py-2 flex items-center space-x-2 font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <ChartBarIcon className="h-5 w-5" />
                            <div>{t('common:dashboard')}</div>
                        </BetterLink> */}

                        <div className="h-[1px] w-full bg-zinc-400/50 dark:bg-zinc-400/50 my-2" />

                        <button
                            onClick={toggleDarkMode}
                            className="px-4 py-2 flex items-center space-x-2 font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            {darkMode ? (
                                <SunIcon className="h-5 w-5" />
                            ) : (
                                <MoonIcon className="h-5 w-5" />
                            )}

                            <div>
                                {ready
                                    ? darkMode
                                        ? t('common:activate_light_theme')
                                        : t('common:activate_dark_theme')
                                    : darkMode
                                    ? 'Activate Light Theme'
                                    : 'Activate Dark Theme'}
                            </div>
                        </button>
                        {ready && (
                            <button
                                onClick={() => setShowLanguages(true)}
                                className="px-4 py-2 text-left font-sans font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <Image
                                            alt={
                                                i18n.language == 'en'
                                                    ? 'united-states flag'
                                                    : 'vietnam flag'
                                            }
                                            src={
                                                i18n.language == 'en'
                                                    ? '/flags/united-states.svg'
                                                    : '/flags/vietnam.svg'
                                            }
                                            width={25}
                                            height={25}
                                        />

                                        <div>
                                            {i18n.language == 'en'
                                                ? 'English'
                                                : 'Tiếng Việt'}
                                        </div>
                                    </div>
                                    <ChevronRightIcon className="h-5 w-5" />
                                </div>
                            </button>
                        )}
                        <BetterLink
                            href="/settings"
                            className="px-4 py-2 flex items-center space-x-2 font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <CogIcon className="h-5 w-5" />
                            <div>
                                {ready ? t('common:settings') : 'Settings'}
                            </div>
                        </BetterLink>

                        <div className="h-[1px] w-full bg-zinc-400/50 dark:bg-zinc-400/50 my-2" />

                        <BetterLink
                            href="/feedback"
                            className="px-4 py-2 flex items-center space-x-2 font-semibold hover:bg-zinc-100/50 dark:hover:bg-zinc-600/30"
                        >
                            <SparklesIcon className="h-5 w-5" />
                            <div>
                                {ready ? t('common:feedback') : 'Feedback'}
                            </div>
                        </BetterLink>
                        <BetterLink
                            onClick={handleLogout}
                            className="rounded-b-lg flex items-center space-x-2 px-4 py-2 font-semibold hover:bg-red-500 hover:text-white dark:hover:bg-red-500/70"
                        >
                            <LogoutIcon className="h-5 w-5" />
                            <div>{ready ? t('common:logout') : 'Logout'}</div>
                        </BetterLink>
                    </div>
                )}
            </Popover.Panel>
        </Popover>
    );
}
