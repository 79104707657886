import BrandLogo from '../../components/common/BrandLogo';
import BetterLink from '../../components/link/better-link';

import { useTheme } from '../../hooks/useTheme';

export default function Logo({
    size,
    showLabel,
    allowRedirect,
    onClick,
    className,
}) {
    const label = 'vohoangphuc';
    const css = 'font-bold text-2xl';

    const { darkMode } = useTheme();

    return (
        <BetterLink
            href={allowRedirect ?? true ? '/?no-redirect=true' : '/'}
            onClick={onClick}
        >
            <div className={`flex space-x-2 ${css} ${className}`}>
                <BrandLogo size={size} darkMode={darkMode} />
                {showLabel && <div>{label}</div>}
            </div>
        </BetterLink>
    );
}
