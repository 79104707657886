import { useSidebar } from '../../hooks/useSidebar';

import Footer from './footer';
import { DashboardHeader, DefaultHeader } from './header';
import Sidebar from './sidebar/Sidebar';

export function DefaultLayout({
    label,
    hideHeader,
    hideFooter,
    darkMode,
    children,
}) {
    return (
        <div className="bg-white dark:bg-zinc-900 text-black dark:text-white">
            {hideHeader || <DefaultHeader darkMode={darkMode} label={label} />}
            <main className="min-h-screen w-full">{children}</main>
            {hideFooter || <Footer darkMode={darkMode} />}
        </div>
    );
}

export function SidebarLayout({
    hideSidebar,
    hideNavBar,
    children,
    className,
    headerClassName,
    sidebarClassName,
}) {
    const { collapsed } = useSidebar();

    const contentCss = `${className} ${collapsed ? 'md:ml-16' : 'md:ml-64'}`;
    const sidebarCss = `${sidebarClassName} ${
        collapsed ? 'md:w-16' : 'md:w-64'
    }`;

    return (
        <div className="flex w-full h-screen">
            {hideSidebar || <Sidebar className={sidebarCss} />}
            <main
                className={`${contentCss} w-full flex flex-col bg-zinc-100 text-black scrollbar-thin scrollbar-track-zinc-100 scrollbar-thumb-zinc-300 dark:bg-[#111113] dark:text-white dark:scrollbar-thumb-zinc-700 dark:scrollbar-track-zinc-800`}
            >
                {hideNavBar || (
                    <DashboardHeader
                        label="vohoangphuc.com"
                        dashboardMode={true}
                        className={headerClassName}
                    />
                )}
                {children}
            </main>
        </div>
    );
}
