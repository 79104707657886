import { useUser } from '../../hooks/useUser';

import Avvvatars from 'avvvatars-react';

const defaultSize = 50;

export function DefaultAvatar({ value, size = defaultSize }) {
    return (
        <Avvvatars
            value={value || 'void'}
            style={value ? 'character' : 'shape'}
            size={size}
        />
    );
}

export function Avatar({ size = defaultSize, url, value, className }) {
    const imageCss = size > 100 ? 'rounded-lg' : 'rounded';

    const { user, userData } = useUser();
    if (!user) return <DefaultAvatar size={size} />;

    const username = userData?.username;
    const userEmail = user?.email;
    const avatarUrl = url ?? userData?.['avatar_url'];
    const displayName = userData?.['display_name'] ?? 'Profile avatar';

    if (avatarUrl)
        return (
            // eslint-disable-next-line @next/next/no-img-element
            <img
                src={avatarUrl}
                alt={displayName}
                width={size}
                height={size}
                className={`aspect-1 ${imageCss} ${className}`}
            />
        );

    return (
        <DefaultAvatar
            value={value ?? username ?? userEmail ?? displayName}
            size={size}
        />
    );
}

export default Avatar;
