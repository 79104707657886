import {
    AcademicCapIcon as OutlinedAcademicCapIcon,
    BeakerIcon as OutlinedBeakerIcon,
    BookOpenIcon as OutlinedBookOpenIcon,
    CashIcon as OutlinedCashIcon,
    ChartBarIcon as OutlinedChartBarIcon,
    ChatIcon as OutlinedChatIcon,
    ClipboardListIcon as OutlinedClipboardListIcon,
    CodeIcon as OutlinedCodeIcon,
    CollectionIcon as OutlinedCollectionIcon,
    DatabaseIcon as OutlinedDatabaseIcon,
    FireIcon as OutlinedFireIcon,
    FolderOpenIcon as OutlinedFolderOpenIcon,
    GlobeIcon as OutlinedGlobeIcon,
    InboxIcon as OutlinedInboxIcon,
    MoonIcon as OutlinedMoonIcon,
    NewspaperIcon as OutlinedNewspaperIcon,
    PlayIcon as OutlinedPlayIcon,
    ServerIcon as OutlinedServerIcon,
} from '@heroicons/react/outline';

import {
    LoginIcon as MenuCloseIcon,
    LogoutIcon as MenuOpenIcon,
    AcademicCapIcon as SolidAcademicCapIcon,
    BeakerIcon as SolidBeakerIcon,
    BookOpenIcon as SolidBookOpenIcon,
    CashIcon as SolidCashIcon,
    ChartBarIcon as SolidChartBarIcon,
    ChatIcon as SolidChatIcon,
    ClipboardListIcon as SolidClipboardListIcon,
    CodeIcon as SolidCodeIcon,
    CollectionIcon as SolidCollectionIcon,
    DatabaseIcon as SolidDatabaseIcon,
    FireIcon as SolidFireIcon,
    FolderOpenIcon as SolidFolderOpenIcon,
    GlobeIcon as SolidGlobeIcon,
    InboxIcon as SolidInboxIcon,
    MoonIcon as SolidMoonIcon,
    NewspaperIcon as SolidNewspaperIcon,
    PlayIcon as SolidPlayIcon,
    ServerIcon as SolidServerIcon,
} from '@heroicons/react/solid';

import { VideogameAsset, VideogameAssetOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';

import Logo from '../../../components/common/Logo';

import { useSidebar } from '../../../hooks/useSidebar';
import { useUser } from '../../../hooks/useUser';

import SidebarTab from './SidebarTab';
import { useRouter } from 'next/router';

import mobile from 'is-mobile';
import { useTranslation } from 'next-i18next';

const allowedEmailsForAI = [
    'phucvo0708@gmail.com',
    'temredalert3@gmail.com',
    'thachanhbo@gmail.com',
    'anhthunguyen020703@gmail.com',
    'toulletou03@gmail.com',
    'pinechen123@gmail.com',
];

export default function Sidebar({ className }) {
    const router = useRouter();
    const { t, ready } = useTranslation();
    const { collapsed, closeSidebar, openSidebar } = useSidebar();
    const { user } = useUser();

    const navigateTo = (e, href) => {
        e.preventDefault();
        if (mobile()) closeSidebar();
        router.push(href);
    };

    return (
        <div
            className={`${className} ${
                collapsed || 'w-full'
            } fixed z-50 h-full max-h-full overflow-x-visible border-r bg-white/70 backdrop-blur-lg dark:border-zinc-800/80 dark:bg-zinc-900/70 dark:text-white md:bg-white md:dark:bg-zinc-900`}
        >
            <div className="flex h-full max-h-full flex-col justify-between">
                <div className="flex-none">
                    <div
                        className={`relative items-center justify-center px-2.5 ${
                            collapsed ? 'hidden md:flex' : 'py-4'
                        }`}
                    >
                        {collapsed ? (
                            <div className="flex-col">
                                <Tooltip
                                    title="Expand sidebar"
                                    placement="bottom-end"
                                    arrow={true}
                                >
                                    <button
                                        onClick={openSidebar}
                                        className="my-[0.825rem] rounded-lg p-2 text-sm font-semibold text-zinc-500 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:text-zinc-400 dark:hover:bg-zinc-700/40 dark:hover:text-white"
                                    >
                                        <MenuOpenIcon className="h-5 w-5" />
                                    </button>
                                </Tooltip>
                            </div>
                        ) : (
                            <div className="mx-2 mt-2 flex justify-between py-4 md:py-0">
                                <Logo
                                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                    onClick={(e) =>
                                        navigateTo(e, '/?no-redirect=true')
                                    }
                                />

                                <Tooltip
                                    title="Collapse sidebar"
                                    placement="bottom-end"
                                    arrow={true}
                                >
                                    <button
                                        onClick={closeSidebar}
                                        className="rounded-lg p-2 text-sm font-semibold text-zinc-500 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:text-zinc-400 dark:hover:bg-zinc-700/40 dark:hover:text-white"
                                    >
                                        <MenuCloseIcon className="h-5 w-5" />
                                    </button>
                                </Tooltip>
                            </div>
                        )}
                    </div>

                    {collapsed && (
                        <div className="hidden h-[0.5px] w-full translate-y-[0.1rem] bg-zinc-300 dark:bg-zinc-800 md:block" />
                    )}
                </div>

                <nav
                    id="sidebar-nav"
                    className={`${
                        collapsed ? 'hidden' : 'place-content-stretch'
                    } space-y-2 md:grid md:grid-cols-1 h-full overflow-y-auto scrollbar-none`}
                >
                    <div className="h-0" />

                    <SidebarTab
                        href="/recommendations/websites"
                        label={
                            ready
                                ? t('tabs:recommended_websites')
                                : 'Recommended Websites'
                        }
                        currentPath={router.pathname}
                        onClick={(e) =>
                            navigateTo(e, '/recommendations/websites')
                        }
                        inactiveIcon={<OutlinedGlobeIcon className="h-5 w-5" />}
                        activeIcon={<SolidGlobeIcon className="h-5 w-5" />}
                    />
                    <SidebarTab
                        href="/messages"
                        label={ready ? t('tabs:messages') : 'Messages'}
                        currentPath={router.pathname}
                        onClick={(e) => navigateTo(e, '/messages')}
                        inactiveIcon={<OutlinedChatIcon className="h-5 w-5" />}
                        activeIcon={<SolidChatIcon className="h-5 w-5" />}
                    />
                    <SidebarTab
                        href="/books"
                        label={ready ? t('tabs:books') : 'Books'}
                        currentPath={router.pathname}
                        onClick={(e) => navigateTo(e, '/books')}
                        inactiveIcon={
                            <OutlinedBookOpenIcon className="h-5 w-5" />
                        }
                        activeIcon={<SolidBookOpenIcon className="h-5 w-5" />}
                    />
                    <SidebarTab
                        href="/courses"
                        label={t(ready ? 'tabs:courses' : 'Courses')}
                        currentPath={router.pathname}
                        onClick={(e) => navigateTo(e, '/courses')}
                        inactiveIcon={<OutlinedPlayIcon className="h-5 w-5" />}
                        activeIcon={<SolidPlayIcon className="h-5 w-5" />}
                    />
                    <SidebarTab
                        href="/sleeptimer"
                        label={ready ? t('tabs:sleep_timer') : 'Sleep Timer'}
                        currentPath={router.pathname}
                        onClick={(e) => navigateTo(e, '/sleeptimer')}
                        inactiveIcon={<OutlinedMoonIcon className="h-5 w-5" />}
                        activeIcon={<SolidMoonIcon className="h-5 w-5" />}
                    />

                    {user?.email == 'phucvo0708@gmail.com' && (
                        <>
                            <SidebarTab
                                href="/changelog"
                                label={
                                    ready ? t('tabs:whats_new') : "What's New?"
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/changelog')}
                                inactiveIcon={
                                    <OutlinedNewspaperIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidNewspaperIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/databases"
                                label={
                                    ready ? t('tabs:databases') : 'Databases'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/databases')}
                                inactiveIcon={
                                    <OutlinedDatabaseIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidDatabaseIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/domains"
                                label={ready ? t('tabs:domains') : 'Domains'}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/domains')}
                                inactiveIcon={
                                    <OutlinedServerIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidServerIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/games"
                                label={ready ? t('tabs:games') : 'Games'}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/games')}
                                inactiveIcon={
                                    <VideogameAssetOutlined className="h-5 w-5" />
                                }
                                activeIcon={
                                    <VideogameAsset className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/academic"
                                label={t(ready ? 'tabs:academic' : 'Academic')}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/academic')}
                                inactiveIcon={
                                    <OutlinedAcademicCapIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidAcademicCapIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/dashboard"
                                label={
                                    ready ? t('tabs:dashboard') : 'Dashboard'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/dashboard')}
                                inactiveIcon={
                                    <OutlinedChartBarIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidChartBarIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/ui"
                                label={ready ? t('tabs:ui') : 'User Interface'}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/ui')}
                                inactiveIcon={
                                    <OutlinedCollectionIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidCollectionIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/expenses"
                                label={
                                    ready
                                        ? t('tabs:expense_tracker')
                                        : 'Expense Tracker'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/expenses')}
                                inactiveIcon={
                                    <OutlinedCashIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidCashIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/projects"
                                label={ready ? t('tabs:projects') : 'Projects'}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/projects')}
                                inactiveIcon={
                                    <OutlinedFolderOpenIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidFolderOpenIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/tasks"
                                label={ready ? t('tabs:tasks') : 'Tasks'}
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/tasks')}
                                inactiveIcon={
                                    <OutlinedClipboardListIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidClipboardListIcon className="h-5 w-5" />
                                }
                            />
                        </>
                    )}

                    {allowedEmailsForAI.includes(user?.email) && (
                        <>
                            <SidebarTab
                                href="/ai/playground"
                                label={
                                    ready
                                        ? t('tabs:ai_playground')
                                        : 'AI Playground'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/ai/playground')}
                                inactiveIcon={
                                    <OutlinedBeakerIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidBeakerIcon className="h-5 w-5" />
                                }
                            />
                        </>
                    )}

                    {user?.email == 'phucvo0708@gmail.com' && (
                        <>
                            <SidebarTab
                                href="/tools/database-updater"
                                label={
                                    ready
                                        ? t('tabs:database_updater')
                                        : 'Database Updater'
                                }
                                currentPath={router.pathname}
                                onClick={(e) =>
                                    navigateTo(e, '/tools/database-updater')
                                }
                                inactiveIcon={
                                    <OutlinedCodeIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidCodeIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/inquiries"
                                label={
                                    ready ? t('tabs:inquiries') : 'Inquiries'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/inquiries')}
                                inactiveIcon={
                                    <OutlinedInboxIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidInboxIcon className="h-5 w-5" />
                                }
                            />
                            <SidebarTab
                                href="/feedbacks"
                                label={
                                    ready ? t('tabs:feedbacks') : 'Feedbacks'
                                }
                                currentPath={router.pathname}
                                onClick={(e) => navigateTo(e, '/feedbacks')}
                                inactiveIcon={
                                    <OutlinedFireIcon className="h-5 w-5" />
                                }
                                activeIcon={
                                    <SolidFireIcon className="h-5 w-5" />
                                }
                            />
                        </>
                    )}

                    <div className="h-8 md:h-4" />
                </nav>
            </div>
        </div>
    );
}
