import BetterLink from '../../components/link/better-link';

export default function TextButton({
    label,
    currentLabel,
    css,
    href,
    onClick,
    children,
}) {
    const selected = currentLabel === label;
    const selectedCss = 'text-blue-600 dark:text-white';

    const defaultProperties =
        'dark:text-zinc-400 hover:text-blue-600 dark:hover:text-white transition duration-300';

    const properties = `${selected && selectedCss} ${css} ${defaultProperties}`;

    return href ? (
        <BetterLink href={href} className={properties} onClick={onClick}>
            {label ?? children}
        </BetterLink>
    ) : (
        <button className={properties} onClick={onClick}>
            {label ?? children}
        </button>
    );
}
