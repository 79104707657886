import { useTheme } from '../../hooks/useTheme';

const Vera = ({ size = 16 }) => {
    const svgRatio = 1.156;

    const width = Math.floor(size);
    const height = Math.floor(size / svgRatio);

    const { darkMode } = useTheme();

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 267 231"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M151.375 147.763C145.532 149.224 139.416 150 133.12 150C126.741 150 120.548 149.204 114.634 147.705L133.022 179.552L151.375 147.763ZM205.263 54.4261L221.703 25.952H189.86C196.909 34.099 202.233 43.7797 205.263 54.4261ZM171.481 10.5391C160.257 3.84552 147.138 0 133.12 0C119.042 0 105.869 3.87906 94.6131 10.6267L88.681 0.352051H0L133.022 230.752L266.043 0.352051H177.362L171.481 10.5391ZM76.3796 25.952H44.3405L60.9123 54.6552C63.9312 43.9185 69.2804 34.1573 76.3796 25.952ZM183.12 75C183.12 102.614 160.734 125 133.12 125C105.506 125 83.12 102.614 83.12 75C83.12 47.3858 105.506 25 133.12 25C160.734 25 183.12 47.3858 183.12 75Z"
                fill={darkMode ? 'white' : 'black'}
            />
        </svg>
    );
};

export default Vera;
