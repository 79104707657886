import { MoonIcon, SunIcon } from '@heroicons/react/outline';

import { Tooltip } from '@mui/material';

import { useTheme } from '../../hooks/useTheme';

import { useTranslation } from 'next-i18next';

export default function DarkModeToggle({ className, hideLabel }) {
    const { darkMode, updateTheme } = useTheme();
    const { t } = useTranslation();

    const toggleDarkMode = () => {
        localStorage.setItem('vhp-dark-mode', (!darkMode).toString());
        updateTheme(!darkMode);
    };

    return (
        <div
            className={`flex items-center justify-between space-x-4 ${className}`}
        >
            {hideLabel || (
                <div className="font-semibold text-black dark:text-zinc-400">
                    {t('common:light')}
                </div>
            )}
            <button
                type="button"
                aria-pressed="false"
                className="relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-zinc-200 transition-colors duration-200 ease-in-out focus:outline-none dark:bg-zinc-600"
                onClick={toggleDarkMode}
            >
                <span className="sr-only">Toggle Themes</span>
                <span
                    aria-hidden="true"
                    className={`
                  ${
                      darkMode ? 'translate-x-5' : 'translate-x-0'
                  } dark:bg-dark-700 inline-block h-5 w-5
                  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out
                `}
                />
            </button>
            {hideLabel || (
                <div className="font-semibold text-zinc-400 dark:text-white">
                    {t('common:dark')}
                </div>
            )}
        </div>
    );
}

export function BlockDarkModeToggle({ className }) {
    const { darkMode, updateTheme } = useTheme();
    const { t } = useTranslation();

    const toggleDarkMode = () => {
        localStorage.setItem('vhp-dark-mode', (!darkMode).toString());
        updateTheme(!darkMode);
    };

    return (
        <button
            onClick={toggleDarkMode}
            className={`flex items-center justify-between px-4 py-2 ${className}`}
        >
            <div className="font-semibold dark:text-zinc-400">
                {t('common:light')}
            </div>
            <button
                type="button"
                aria-pressed="false"
                className="relative mx-5 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-zinc-200 transition-colors duration-200 ease-in-out focus:outline-none dark:bg-zinc-600"
                onClick={toggleDarkMode}
            >
                <span className="sr-only">Toggle Themes</span>
                <span
                    aria-hidden="true"
                    className={`
                  ${
                      darkMode ? 'translate-x-5' : 'translate-x-0'
                  } dark:bg-dark-700 inline-block h-5 w-5
                  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out
                `}
                />
            </button>
            <div className="font-semibold text-zinc-400 dark:text-white">
                {t('common:dark')}
            </div>
        </button>
    );
}

export function DarkModeToggleIcon() {
    const { darkMode, updateTheme } = useTheme();

    const toggleDarkMode = () => {
        localStorage.setItem('vhp-dark-mode', (!darkMode).toString());
        updateTheme(!darkMode);
    };

    return (
        <button onClick={toggleDarkMode} className="md:flex">
            <Tooltip
                title={
                    darkMode ? 'Switch to Light mode' : 'Switch to Dark mode'
                }
            >
                <div className="justify-center rounded-lg p-2 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:hover:bg-zinc-700/40 dark:hover:text-white">
                    {darkMode ? (
                        <SunIcon className="h-5 w-5" />
                    ) : (
                        <MoonIcon className="h-5 w-5" />
                    )}
                </div>
            </Tooltip>
        </button>
    );
}
