import { DashboardNavBar, NavBar } from './navbar/NavBar';

export function DefaultHeader({ label, className }) {
    return (
        <header className={`sticky top-0 md:top-4 z-40 ${className}`}>
            <NavBar label={label} />
        </header>
    );
}

export function DashboardHeader({ label, className }) {
    return (
        <header className={`sticky top-0 z-40 ${className}`}>
            <DashboardNavBar label={label} />
        </header>
    );
}
