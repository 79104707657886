import {
    BellIcon,
    ChatAlt2Icon,
    LoginIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import { MenuIcon } from '@heroicons/react/outline';

import { XIcon } from '@heroicons/react/solid';

import { Tooltip } from '@mui/material';

import DropdownProfile from '../../../components/beta/dropdown-profile';
import TextButton from '../../../components/buttons/text-button';
import Logo from '../../../components/common/Logo';
import SidebarTab from '../../../components/layout/sidebar/SidebarTab';
import BetterLink from '../../../components/link/better-link';

import { useSidebar } from '../../../hooks/useSidebar';
import { useUser } from '../../../hooks/useUser';

import { APP_VERSION } from '../../../constants/core';
import { DarkModeToggleIcon } from '../../buttons/darkmode-toggle';
import { useRouter } from 'next/router';

import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export function NavBar({ label }) {
    const [scrolled, setScrolled] = useState(0);
    const [menuClosed, setMenuClosed] = useState(true);

    const handleScroll = () => setScrolled(window.scrollY);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const { user } = useUser();

    const router = useRouter();
    const { t } = useTranslation();

    const openMenu = () => setMenuClosed(false);
    const closeMenu = () => setMenuClosed(true);

    const navigateTo = (e, href) => {
        e.preventDefault();
        closeMenu();
        router.push(href);
    };

    return (
        <nav
            className={`flex items-center justify-between border p-4 md:rounded-2xl dark:text-zinc-400 transition duration-300 md:mx-16 lg:mx-32 xl:mx-64 ${
                scrolled
                    ? 'border-zinc-50 shadow shadow-zinc-800/10 dark:shadow-zinc-400/10 dark:border-zinc-800/70'
                    : 'border-transparent shadow-none dark:border-transparent'
            } ${
                menuClosed
                    ? 'bg-white/60 dark:bg-zinc-900/60 backdrop-blur'
                    : 'bg-white dark:bg-zinc-900'
            }`}
        >
            {menuClosed || (
                <div className="fixed inset-0 z-50 bg-white/70 backdrop-blur dark:bg-zinc-900/70 md:hidden md:bg-white md:dark:bg-zinc-900">
                    <div className="relative flex items-center justify-center px-2.5 py-4">
                        <Logo
                            onClick={(e) => navigateTo(e, '/?no-redirect=true')}
                        />
                        <button
                            className="absolute right-4 rounded-full p-2 md:hidden"
                            onClick={closeMenu}
                        >
                            <XIcon className="h-6 w-6" />
                        </button>
                    </div>
                    <nav id="sidebar-nav" className="grid">
                        {/* <SidebarTab
                            href="/portfolio"
                            label={t('common:portfolio')}
                            currentPath={router.pathname}
                            onClick={(e) => navigateTo(e, '/portfolio')}
                        >
                            <BriefcaseIcon className="h-5 w-5" />
                        </SidebarTab>
                        <SidebarTab
                            href="/blog"
                            label={t('common:blog')}
                            currentPath={router.pathname}
                            onClick={(e) => navigateTo(e, '/blog')}
                        >
                            <NewspaperIcon className="h-5 w-5" />
                        </SidebarTab> */}
                        {/* <SidebarTab
                            href="/about"
                            label={t('common:about')}
                            currentPath={router.pathname}
                            onClick={(e) => navigateTo(e, '/about')}
                        >
                            <IdentificationIcon className="h-5 w-5" />
                        </SidebarTab> */}
                        <SidebarTab
                            href="/contact"
                            label={t('common:contact')}
                            isMobile={true}
                            currentPath={router.pathname}
                            inactiveIcon={<ChatAlt2Icon className="h-5 w-5" />}
                            onClick={(e) => navigateTo(e, '/contact')}
                        />
                    </nav>
                </div>
            )}

            <div className="flex items-center space-x-4">
                {menuClosed && (
                    <button className="p-2 text-zinc-300" onClick={openMenu}>
                        <MenuIcon className="h-6 w-6 md:hidden" />
                    </button>
                )}
                <Logo />
            </div>

            <div className="absolute top-1/2 left-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform space-x-12 md:block">
                {/* <TextButton
                    label={t('common:about')}
                    href="/about"
                    currentLabel={label}
                />
                <TextButton
                    label={
                        t('common:projects') == 'projects'
                            ? 'Projects'
                            : t('common:projects')
                    }
                    href="/projects"
                    currentLabel={label}
                /> */}
                <TextButton
                    label={t('common:contact')}
                    href="/contact"
                    currentLabel={label}
                />
                {/* <TextButton
                    label={t('common:blog')}
                    href="/blog"
                    currentLabel={label}
                /> */}
            </div>

            <div className="flex items-center justify-center space-x-2">
                {user ? (
                    <>
                        <DarkModeToggleIcon />
                        <DropdownProfile />
                    </>
                ) : (
                    <>
                        <DarkModeToggleIcon />
                        <Tooltip title="Login">
                            <button
                                href="/login"
                                className="justify-center rounded-lg p-2 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:hover:bg-zinc-700/40 dark:hover:text-white"
                                onClick={(e) => navigateTo(e, '/login')}
                            >
                                <LoginIcon className="h-5 w-5" />
                            </button>
                        </Tooltip>
                    </>
                )}
            </div>
        </nav>
    );
}

export function DashboardNavBar({ label }) {
    const { openSidebar } = useSidebar();

    return (
        <nav className="justify-between border-b border-zinc-300 bg-white py-2 pl-2 dark:border-zinc-800/80 dark:bg-zinc-900 dark:text-white">
            <div className="flex items-center justify-between">
                <div className="flex w-[16rem] max-w-[16rem] items-center space-x-2">
                    <button
                        onClick={openSidebar}
                        className="block rounded-lg p-3 text-sm font-semibold text-black hover:bg-blue-100/50 hover:text-blue-600 dark:text-white dark:hover:bg-zinc-700/40 md:hidden"
                    >
                        <MenuIcon className="h-5 w-5" />
                    </button>
                    {label && (
                        <div className="cursor-default flex items-center">
                            <div className="font-bold text-sm md:text-xl text-transparent bg-clip-text bg-gradient-to-br dark:from-blue-400 dark:via-purple-300 dark:to-red-400 from-blue-600 via-purple-500 to-red-500">
                                {label}
                            </div>

                            <div className="font-bold md:translate-y-[0.15rem] text-white bg-gradient-to-br from-blue-600 via-purple-500 to-red-500 dark:from-blue-400/50 dark:via-purple-500/50 dark:to-red-500/50 rounded text-xs ml-2 px-2 py-0.5">
                                v{APP_VERSION}
                            </div>
                        </div>
                    )}
                </div>

                <div className="mr-4 flex items-center space-x-2">
                    <BetterLink href="/contact" className="hidden md:flex">
                        <Tooltip title="Help Center" arrow={true}>
                            <button className="justify-center rounded-lg p-2 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:hover:bg-zinc-700/40 dark:hover:text-white">
                                <QuestionMarkCircleIcon className="h-5 w-5" />
                            </button>
                        </Tooltip>
                    </BetterLink>

                    <BetterLink href="/coming-soon" className="hidden md:flex">
                        <Tooltip title="Notifications" arrow={true}>
                            <button className="justify-center rounded-lg p-2 transition duration-300 hover:bg-blue-100/50 hover:text-blue-600 dark:hover:bg-zinc-700/40 dark:hover:text-white">
                                <BellIcon className="h-5 w-5" />
                            </button>
                        </Tooltip>
                    </BetterLink>

                    <DropdownProfile />
                </div>
            </div>
        </nav>
    );
}
